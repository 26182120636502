import React, { useEffect, useState } from "react";
import "./UnknownOrders.css";
import UnavailableBox from "./UnavailableBox";

const UnknownOrders = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const adminStatus = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(adminStatus);
  }, []);

  if (!isAdmin) {
    return <UnavailableBox />;
  }

  return (
    <div className="orders-content">
      <h1>Неизвестные ордеры</h1>
      <p>Список ордеров, которые не удалось идентифицировать:</p>
    </div>
  );
};

export default UnknownOrders;
