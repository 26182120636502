import React from "react";
import "./UnavailableBox.css"; // Импортируем стили
import { FaExclamationTriangle } from "react-icons/fa";

const UnavailableBox = () => {
  return (
    <div className="unavailable-box">
      <FaExclamationTriangle className="warning-icon" />
      <p>Недоступно</p>
      <p className="reason-text">
        Для доступа к этой функции, внесите страховой депозит.
      </p>
      <a href="/topup" className="topup-link">
        Пополнить депозит
      </a>
    </div>
  );
};

export default UnavailableBox;
