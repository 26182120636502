import React, { useEffect, useState } from "react";
import "./Profile.css";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const loadProfileData = async () => {
      try {
        const response = await fetch("/config.json");
        const data = await response.json();
        setProfileData(data);
      } catch (error) {
        console.error("Error loading profile data:", error);
      }
    };

    const adminStatus = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(adminStatus);

    const storedUsername = localStorage.getItem("currentUser");
    setUsername(storedUsername || "Имя пользователя не задано");

    loadProfileData();
  }, []);

  if (!profileData) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="content">
      <h1>Профиль</h1>
      <div className="profile-section">
        <h2>Личная информация</h2>
        <div className="profile-info">
          <label>Имя:</label>
          <span>{username}</span>
        </div>
      </div>

      {isAdmin && (
        <>
          <div className="profile-section">
            <h2>Информация об аккаунте</h2>
            <div className="profile-info">
              <label>Имя пользователя:</label>
              <span>{profileData.accountInfo.username}</span>
            </div>
          </div>

          <div className="profile-section">
            <h2>Настройки</h2>
            <div className="profile-info">
              <label>Язык:</label>
              <span>{profileData.settings.language}</span>
            </div>
            <div className="profile-info">
              <label>Уведомления:</label>
              <span>{profileData.settings.notifications}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
