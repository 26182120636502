import React, { useEffect, useState } from "react";
import "./Orders.css";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import UnavailableBox from "./UnavailableBox";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    id: "",
    paymentMethod: "",
    startDate: "",
    endDate: "",
    status: "",
    fiat: "",
  });
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "asc",
  });

  const isAdmin = localStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const response = await fetch("/orders.json");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error loading orders data:", error);
      }
    };

    if (isAdmin) {
      loadOrders();
    } else {
      setOrders([]);
    }
  }, [isAdmin]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const clearFilters = () => {
    setFilters({
      id: "",
      paymentMethod: "",
      startDate: "",
      endDate: "",
      status: "",
      fiat: "",
    });
  };

  const parseValue = (value) => {
    return parseFloat(value.replace(/[₽$]/g, "").replace(",", "."));
  };

  const filterOrders = () => {
    if (!isAdmin) return [];

    const filtered = orders.filter((order) => {
      const startDate = new Date(filters.startDate);
      const endDate = new Date(filters.endDate);
      const orderDate = new Date(order.date);

      return (
        (filters.id === "" || order.id.includes(filters.id)) &&
        (filters.paymentMethod === "" ||
          order.paymentMethod === filters.paymentMethod) &&
        (filters.status === "" || order.status === filters.status) &&
        (filters.fiat === "" || order.fiatAmount.includes(filters.fiat)) &&
        (filters.startDate === "" || orderDate >= startDate) &&
        (filters.endDate === "" || orderDate <= endDate)
      );
    });

    if (sortConfig !== null) {
      filtered.sort((a, b) => {
        const aValue =
          sortConfig.key === "referencePrice"
            ? parseValue(a[sortConfig.key])
            : a[sortConfig.key];
        const bValue =
          sortConfig.key === "referencePrice"
            ? parseValue(b[sortConfig.key])
            : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredOrders = filterOrders();

  if (!isAdmin) {
    return <UnavailableBox />;
  }

  return <UnavailableBox />;
};

export default Orders;
