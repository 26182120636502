import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Balance.css";

const Balance = () => {
  const [balance, setBalance] = useState(0);
  const [pendingPayments, setPendingPayments] = useState(0);
  const [configData, setConfigData] = useState({});
  const username = localStorage.getItem("currentUser");
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch("/users.json");
        const users = await response.json();

        const user = users.find((user) => user.username === username);
        if (user) {
          // Parse balance from string to number
          const balanceString = user.balance.replace(" ", "").replace(",", ".");
          const parsedBalance = parseFloat(balanceString);
          setConfigData(user);
          setBalance(parsedBalance);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, [username]);

  const handleTopUp = () => {
    navigate("/topup");
  };

  return (
    <div className="content">
      <h1>Баланс</h1>
      <div className="balance-info">
        <div className="balance-card">
          <h2>Текущий баланс</h2>
          <p className="balance-amount">{balance.toLocaleString()} ₽</p>
        </div>
        <div className="balance-card">
          <h2>Ожидаемые платежи</h2>
          <p className="balance-amount">0 ₽</p>
        </div>
      </div>
      <button onClick={handleTopUp} className="top-up-button">
        Пополнить
      </button>
    </div>
  );
};

export default Balance;
