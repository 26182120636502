import React, { useEffect, useState } from "react";
import "./Statistics.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from "recharts";
import sber from "../sber.png";
import tink from "../tink.png";
import { FaTimes, FaChevronDown } from "react-icons/fa";
import UnavailableBox from "./UnavailableBox";
const Statistics = () => {
  const [statistics, setStatistics] = useState({
    turnover: 0,
    income: 0,
    payments: 0,
    rate: 5.0,
    deposits: 0,
    activeCards: 0,
    activeBanks: 0,
    totalTurnover: 0,
    totalPayments: 0,
  });

  const [chartData, setChartData] = useState([
    { name: "10.07", оборот: 0 },
    { name: "11.07", оборот: 0 },
    { name: "12.07", оборот: 0 },
    { name: "13.07", оборот: 0 },
    { name: "14.07", оборот: 0 },
    { name: "15.07", оборот: 0 },
    { name: "16.07", оборот: 0 },
    { name: "17.07", оборот: 0 },
    { name: "18.07", оборот: 0 },
    { name: "19.07", оборот: 0 },
    { name: "20.07", оборот: 0 },
    { name: "21.07", оборот: 0 },
  ]);

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin") === "true";

    if (isAdmin) {
      const calculateStatistics = (ordersData) => {
        let turnover = 0;
        let income = 0;
        let payments = 0;
        let newChartData = [];

        ordersData.forEach((order) => {
          const orderDate = new Date(order.date);
          const fiatAmount = parseFloat(
            order.fiatAmount.replace(/[^\d.-]/g, "")
          );

          if (!isNaN(fiatAmount)) {
            turnover += fiatAmount;
            income += (fiatAmount * statistics.rate) / 100;
            payments += 1;

            newChartData.push({
              name: `${orderDate.getDate()}.${orderDate.getMonth() + 1}`,
              оборот: turnover,
            });
          }
        });

        if (newChartData.length === 0) {
          newChartData = [{ name: "01.01", оборот: 0 }];
        }

        setStatistics((prevStatistics) => ({
          ...prevStatistics,
          turnover,
          income,
          payments,
        }));

        setChartData(newChartData);
      };

      fetch("/orders.json")
        .then((response) => response.json())
        .then((data) => calculateStatistics(data))
        .catch((error) => {
          console.error("Error loading orders data:", error);
          setStatistics((prevStatistics) => ({
            ...prevStatistics,
            turnover: 0,
            income: 0,
            payments: 0,
          }));
          setChartData([{ name: "01.01", оборот: 0 }]);
        });
    }
  }, []);

  return <UnavailableBox />;
};

export default Statistics;
