import { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa"; // Импортируем иконку спиннера
import "./TopUp.css";

const TopUp = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Состояние для спиннера

  useEffect(() => {
    const isAdminValue = localStorage.getItem("isAdmin");
    setIsAdmin(isAdminValue === "true");
  }, []);

  const handleCheckTransaction = () => {
    setIsLoading(true); // Включаем спиннер

    setTimeout(() => {
      setIsLoading(false); // Останавливаем спиннер через 3 секунды
      // Здесь может быть логика для проверки транзакции
    }, 3000);
  };

  return (
    <div className="topup-content">
      <div className="topup-info">
        <h2>Реквизиты для пополнения</h2>
        <p className="crypto-type">Tether USD (USDT)</p>
        <img src="/qr.jpg" alt="QR Code" className="qr-code" />
        <p className="wallet-address">
          Кошелек: THJQHzr2PihAV5SjDpH5qf6mSgURPL9zp9
        </p>
        <p className="info-text">
          Пополнение будет автоматически обработано в течение 30 минут.
          <br />
          <strong>
            Не забудьте проверить, что введенный адрес правильный!
          </strong>
        </p>
        <button
          className="action-button"
          onClick={handleCheckTransaction}
          disabled={isLoading} // Блокируем кнопку, пока идет проверка
        >
          {isLoading ? (
            <FaSpinner className="spinner-icon" />
          ) : (
            "Проверить транзакцию"
          )}
        </button>
      </div>
    </div>
  );
};

export default TopUp;
