import React, { useEffect, useState } from "react";
import "./TransactionHistory.css";
import UnavailableBox from "./UnavailableBox";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    if (isAdmin) {
      fetch("/orders.json")
        .then((response) => response.json())
        .then((data) => {
          const formattedTransactions = data.map((order) => ({
            id: order.id,
            date: order.date,
            amount: order.fiatAmount,
            status: order.status,
          }));
          setTransactions(formattedTransactions);
        })
        .catch((error) => console.error("Error loading orders:", error));
    }
  }, [isAdmin]);

  if (!isAdmin) {
    return (
      <table className="transaction-history-table">
        <thead>
          <tr>
            <th className="transaction-history-header">ID транзакции</th>
            <th className="transaction-history-header">Дата</th>
            <th className="transaction-history-header">Сумма</th>
            <th className="transaction-history-header">Статус</th>
          </tr>
        </thead>
      </table>
    );
  }

  return (
    <div className="transaction-history-container">
      <h1 className="transaction-history-title">История транзакций</h1>
      <table className="transaction-history-table">
        <thead>
          <tr>
            <th className="transaction-history-header">ID транзакции</th>
            <th className="transaction-history-header">Дата</th>
            <th className="transaction-history-header">Сумма</th>
            <th className="transaction-history-header">Статус</th>
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((transaction) => (
              <tr key={transaction.id} className="transaction-history-row">
                <td className="transaction-history-cell">{transaction.id}</td>
                <td className="transaction-history-cell">{transaction.date}</td>
                <td className="transaction-history-cell">
                  {transaction.amount}
                </td>
                <td className="transaction-history-cell">
                  {transaction.status}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="no-transactions">
                Нет транзакций для отображения
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionHistory;
