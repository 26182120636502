import React from "react";
import "./Dashboard.css";
import UnavailableBox from "./UnavailableBox";

const Dashboard = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  if (!isAdmin) {
    return <UnavailableBox />;
  }
  return (
    <div className="content">
      <h1>Дашбоард</h1>
      {isAdmin ? (
        <div className="dashboard-widgets">
          <div className="widget">
            <h2>Количество ордеров</h2>
            <p>0</p>
          </div>
          <div className="widget">
            <h2>Активные пользователи</h2>
            <p>136</p>
          </div>
        </div>
      ) : (
        <div className="dashboard-widgets">
          <div className="widget">
            <h2>Количество ордеров</h2>
            <p>(недоступно)</p>
          </div>
          <div className="widget">
            <h2>Активные пользователи</h2>
            <p>(недоступно)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
