import React, { useState, useEffect } from "react";
import "./PaymentMethods.css";
import UnavailableBox from "./UnavailableBox";

const PaymentMethods = () => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const [paymentType, setPaymentType] = useState("card");
  const [cardNumber, setCardNumber] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const storedPaymentMethods =
      JSON.parse(localStorage.getItem("paymentMethods")) || [];
    setPaymentMethods(storedPaymentMethods);
  }, []);

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleCryptoAddressChange = (event) => {
    setCryptoAddress(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newPaymentMethod =
      paymentType === "card"
        ? { type: "card", cardNumber }
        : { type: "crypto", cryptoAddress };
    const updatedPaymentMethods = [...paymentMethods, newPaymentMethod];
    setPaymentMethods(updatedPaymentMethods);
    localStorage.setItem(
      "paymentMethods",
      JSON.stringify(updatedPaymentMethods)
    );
    setCardNumber("");
    setCryptoAddress("");
  };

  const handleDelete = (index) => {
    const updatedPaymentMethods = paymentMethods.filter((_, i) => i !== index);
    setPaymentMethods(updatedPaymentMethods);
    localStorage.setItem(
      "paymentMethods",
      JSON.stringify(updatedPaymentMethods)
    );
  };

  return (
    <div className="payment-methods">
      <div className="payment-methods-content">
        <form className="payment-methods-form" onSubmit={handleSubmit}>
          <label className="payment-methods-label">
            Выберите способ оплаты:
            <select
              className="payment-methods-select"
              value={paymentType}
              onChange={handlePaymentTypeChange}
            >
              <option value="card">Карта</option>
              <option value="crypto">Криптокошелек</option>
            </select>
          </label>
          {paymentType === "card" ? (
            <div className="payment-methods-input-group">
              <label className="payment-methods-label">
                Номер карты:
                <input
                  type="text"
                  className="payment-methods-input"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  required
                />
              </label>
            </div>
          ) : (
            <div className="payment-methods-input-group">
              <label className="payment-methods-label">
                Адрес криптокошелька:
                <input
                  type="text"
                  className="payment-methods-input"
                  value={cryptoAddress}
                  onChange={handleCryptoAddressChange}
                  required
                />
              </label>
            </div>
          )}
          <button type="submit" className="payment-methods-add-button">
            Добавить способ оплаты
          </button>
        </form>

        <h2 className="payment-methods-heading">Сохраненные способы оплаты</h2>
        <ul className="payment-methods-list">
          {paymentMethods.map((method, index) => (
            <li key={index} className="payment-methods-list-item">
              {method.type === "card"
                ? `Карта: ${method.cardNumber}`
                : `Криптокошелек: ${method.cryptoAddress}`}
              <button
                onClick={() => handleDelete(index)}
                className="payment-methods-delete-button"
              >
                Удалить
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PaymentMethods;
