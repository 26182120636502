import React, { useState, useEffect } from "react";
import "./Tickets.css";
import UnavailableBox from "./UnavailableBox";

const Tickets = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    language: "",
    timezone: "",
    subject: "",
    description: "",
  });

  useEffect(() => {
    const adminStatus = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(adminStatus);

    if (adminStatus) {
      setFormData({
        username: "adminUser",
        name: "Admin Name",
        language: "Русский",
        timezone: "UTC+3",
        subject: "",
        description: "",
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Форма создания тикета отправлена.");
  };

  if (!isAdmin) {
    return <UnavailableBox />;
  }

  return <UnavailableBox />;
};

export default Tickets;
