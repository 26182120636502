import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Orders from "./components/Orders";
import Balance from "./components/Balance";
import TransactionHistory from "./components/TransactionHistory";
import PaymentMethods from "./components/PaymentMethods";
import Statistics from "./components/Statistics";
import Tickets from "./components/Tickets";
import Profile from "./components/Profile";
import UnknownOrders from "./components/UnknownOrders";
import TopBar from "./components/TopBar";
import Login from "./components/Login";
import "./App.css";
import TopUp from "./components/TopUp";

const App = () => {
  const [balance, setBalance] = useState("");
  const [profileName, setProfileName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const auth = localStorage.getItem("isAuthenticated");
      setIsAuthenticated(auth === "true");
    };

    const fetchConfig = () => {
      fetch("/config.json")
        .then((response) => response.json())
        .then((data) => {
          if (data && data.balance && data.profileName) {
            setBalance(data.balance.currentBalance || "0");
            setProfileName(data.profileName || "Гость");
          }
        })
        .catch((error) => console.error("Error loading config:", error));
    };

    checkAuth();
    fetchConfig();
  }, []);

  return (
    <Router>
      <div className="App">
        {isAuthenticated && (
          <>
            <TopBar
              balance={balance}
              profileName={profileName}
              className={isAuthenticated ? "" : "unauthenticated"}
            />
            <Sidebar className={isAuthenticated ? "" : "unauthenticated"} />
          </>
        )}
        <div
          className={`main-content ${isAuthenticated ? "" : "unauthenticated"}`}
        >
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/transaction-history" />
                ) : (
                  <Login setIsAuthenticated={setIsAuthenticated} />
                )
              }
            />
            <Route
              path="/dashboard"
              element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
            />
            <Route
              path="/orders"
              element={isAuthenticated ? <Orders /> : <Navigate to="/" />}
            />
            <Route
              path="/balance"
              element={isAuthenticated ? <Balance /> : <Navigate to="/" />}
            />
            <Route
              path="/transaction-history"
              element={
                isAuthenticated ? <TransactionHistory /> : <Navigate to="/" />
              }
            />
            <Route path="/topup" element={<TopUp />} />
            <Route
              path="/payment-methods"
              element={
                isAuthenticated ? <PaymentMethods /> : <Navigate to="/" />
              }
            />
            <Route
              path="/statistics"
              element={isAuthenticated ? <Statistics /> : <Navigate to="/" />}
            />
            <Route
              path="/tickets"
              element={isAuthenticated ? <Tickets /> : <Navigate to="/" />}
            />
            <Route
              path="/profile"
              element={isAuthenticated ? <Profile /> : <Navigate to="/" />}
            />
            <Route
              path="/unknown-orders"
              element={
                isAuthenticated ? <UnknownOrders /> : <Navigate to="/" />
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
