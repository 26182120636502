import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import "./TopBar.css";
import sber from "../sber.png"; // Убедитесь, что путь к изображению корректный

const TopBar = () => {
  const [balance, setBalance] = useState("0 RUB");
  const [profileName, setProfileName] = useState("Гость");

  const username = localStorage.getItem("currentUser");

  useEffect(() => {
    fetch("/users.json")
      .then((response) => response.json())
      .then((users) => {
        const user = users.find((user) => user.username === username);
        if (user) {
          setBalance(user.balance);
          setProfileName(user.username);
        }
      })
      .catch((error) => console.error("Error loading users:", error));
  }, [username]);

  return (
    <div className="top-bar">
      <h2 className="top-bar-logo">PayDrop</h2>
      <div className="top-bar-content">
        <div className="status-info-container">
          <div className="profile-info">
            <p className="balance">Баланс: {balance}</p>
            <p className="profile-name">
              <FaUser style={{ marginRight: "5px" }} />
              {profileName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
