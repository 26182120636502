import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaTachometerAlt, // Это иконка для дашборда
  FaShoppingCart,
  FaWallet,
  FaHistory,
  FaCreditCard,
  FaChartLine,
  FaTicketAlt,
  FaQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import "./Sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("currentUser");
    // Add more items as needed

    navigate("/"); // Redirect to login page after logout
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="sidebar">
      <ul>
        <li>
          <NavLink to="/dashboard" activeClassName="active">
            <FaTachometerAlt className="icon" /> Дашбоард
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders" activeClassName="active">
            <FaShoppingCart className="icon" /> Ордеры
          </NavLink>
        </li>
        <li>
          <NavLink to="/balance" activeClassName="active">
            <FaWallet className="icon" /> Баланс
          </NavLink>
        </li>
        <li>
          <NavLink to="/transaction-history" activeClassName="active">
            <FaHistory className="icon" /> История транзакций
          </NavLink>
        </li>
        <li>
          <NavLink to="/payment-methods" activeClassName="active">
            <FaCreditCard className="icon" /> Способы оплаты
          </NavLink>
        </li>
        <li>
          <NavLink to="/statistics" activeClassName="active">
            <FaChartLine className="icon" /> Статистика
          </NavLink>
        </li>
        <li>
          <NavLink to="/tickets" activeClassName="active">
            <FaTicketAlt className="icon" /> Тикеты
          </NavLink>
        </li>
        <li>
          <NavLink to="/unknown-orders" activeClassName="active">
            <FaQuestionCircle className="icon" /> Неизвестные ордеры
          </NavLink>
        </li>
        <li>
          <button className="logout-button" onClick={handleLogout}>
            <FaSignOutAlt className="icon" /> Выход
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
